<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <button
      class="btn primary-bg-color text-white mt-5"
      @click="$router.go(-1)"
    >
      Back
    </button>
    <div class="card position-relative" style="top: 10px">
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col-md-2">
              <div class="row align-items-center justify-content-center">
                <div class="col-md-12 text-center">
                  <img
                    class="mb-3"
                    :src="detail?.user?.profile_image?.image_url"
                    style="
                      width: 120px;
                      height: 120px;
                      border-radius: 50%;
                      border: 1px solid #ddd;
                    "
                    v-if="detail?.user?.profile_image?.image_url"
                  />

                  <img
                    v-else
                    class="mb-md-0 mb-3 rounded-circle"
                    src="../../assets/images/usericon.png"
                    alt=""
                    style="
                      width: 150px;
                      height: 100px;
                      object-fit: fill;
                      background-color: transparent;
                    "
                  />
                  <div>
                    <b>{{ detail?.user?.name }}</b>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-5">
              <div class="row">
                <div class="col-md-3">
                  <div class="row my-2">
                    <p style="font-weight: bold">Department</p>
                  </div>
                  <div class="row my-2">
                    <p style="font-weight: bold">Phone</p>
                  </div>
                  <div class="row my-2">
                    <p style="font-weight: bold">Email</p>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="row my-2">
                    {{ detail?.user?.department?.name }}
                  </div>
                  <div class="row my-2">{{ detail?.user?.phone }}</div>
                  <div class="row my-2">{{ detail?.user?.email }}</div>
                </div>
              </div>
            </div>

            <div class="col-md-5">
              <div class="row">
                <div class="col-md-4">
                  <div class="row my-2">
                    <p style="font-weight: bold">In Progress Course</p>
                  </div>
                  <div class="row my-2">
                    <p style="font-weight: bold">Completed Course</p>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row my-2">
                    {{ detail?.inProgress }}
                  </div>
                  <div class="row my-2">{{ detail?.complete }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-4">
              <div class="form-group mb-3">
                <select
                  class="select-form-control"
                  v-model="course"
                  @change="selectedCourse"
                  required
                >
                  <option value="" selected>Select batch</option>
                  <option v-for="course in courses" :value="course.batch.id">
                    {{ course?.batch?.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div
              v-if="detail.completed_at != null"
              class="col-md-4 py-4"
              style="background-color: #e3f3ff"
            >
              <p v-if="detail && detail.totalPercentage >= 50">
                {{ detail?.user?.name }} has successfully completed this course
                with a grade of {{ calculateGrade(detail.totalPercentage) }}.
              </p>
              <p v-else>{{ detail?.user?.name }} has failed this course.</p>
              <span style="color: #0074ce">
                {{ detail?.totalPercentage }}%</span
              >
            </div>

            <div v-if="detail.completed_at != null" class="col-md-1"></div>
            <div v-if="detail?.course?.name" class="col-md-5">
              <div class="row no-gutters">
                <div class="col-md-3">
                  <strong>Course</strong>
                </div>
                <div class="col-md-9">
                  {{ detail?.course?.name }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <strong>Duration</strong>
                </div>
                <div class="col-md-9">{{ detail.start_date }} - {{ detail.end_date }}</div>
              </div>
            </div>
          </div>

          <div
            v-for="(course, index) in detail?.batch_shedule"
            class="accordion video-accordion objective-accordion pb-0 px-0 mb-4 mt-3"
            :id="'accordion' + index"
          >
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed fw-semibold"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse' + index"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {{ course?.level.name }}
                </button>
              </h2>
              <div
                :id="'collapse' + index"
                class="accordion-collapse collapse"
                :data-bs-parent="'accordion' + index"
              >
                <div class="accordion-body p-0">
                  <div
                    v-for="mat in course.material"
                    class="video-section dark-gray-color d-flex justify-content-between align-items-center border-top p-3 text-black"
                  >
                    <div class="d-flex align-items-center col-6">
                      <div class="d-sm-block d-none icon-sm">
                        <img
                          v-if="mat.material.type == 'Quiz'"
                          src="../../assets/images/quiz-icon.png"
                          alt=""
                        />
                        <img
                          v-if="mat.material.type == 'Document'"
                          src="../../assets/images/document_letter_icon.png"
                          alt=""
                        />
                        <img
                          v-if="mat.material.type == 'Video'"
                          src="../../assets/images/video_letter_icon.png"
                          alt=""
                        />
                        <img
                          v-if="mat.material.type == 'Assignment'"
                          src="../../assets/images/manual.jpg"
                          alt=""
                        />
                      </div>
                      <div class="fs-15 ps-sm-3">
                        <p v-if="mat.material.type == 'Quiz'">
                          {{ mat.material.material.name }}
                        </p>
                        <p v-else>
                          {{ mat.material.material.title }}
                        </p>
                      </div>
                    </div>
                    <div class="col-2 fs-13">
                      <!-- {{ mat?.submission?.created_at }} -->
                    </div>
                    <div class="col-2 fs-13" v-if="mat?.submission">
                      {{ mat?.submission?.percentage }}
                    </div>
                    <div
                      class="col-2 fs-13"
                      v-if="
                        !mat?.submission &&
                        (mat.material.type == 'Quiz' ||
                          mat.material.type == 'Assignment')
                      "
                    >
                      Not Submitted
                    </div>
                    <div class="col-2 fs-13">Course Material</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="detail?.assignemnts?.length > 0"
            class="accordion video-accordion objective-accordion pb-0 px-0 mb-4 mt-5"
            id="accordionassignments"
          >
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed fw-semibold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Assignments
                </button>
              </h2>
              <div
                id="collapse"
                class="accordion-collapse collapse"
                data-bs-parent="accordionassignments"
              >
                <div class="accordion-body p-0">
                  <div
                    v-for="assg in detail?.assignemnts"
                    class="video-section dark-gray-color d-flex justify-content-between align-items-center border-top p-3 text-black"
                  >
                    <div class="d-flex align-items-center col-6">
                      <div class="d-sm-block d-none">
                        <img
                          v-if="assg?.assignment_type == 'Quiz'"
                          src="../../assets/images/quiz-icon.png"
                          alt=""
                        />
                        <img
                          v-else
                          src="../../assets/images/manual.jpg"
                          alt=""
                        />
                      </div>
                      <div class="fs-15 ps-sm-3">
                        <p v-if="assg?.assignment_type == 'Quiz'">
                          {{ assg?.material?.name }}
                        </p>
                        <p v-else>{{ assg?.material?.title }}</p>
                      </div>
                    </div>
                    <div class="col-2 fs-13">
                      {{ mat?.submission?.created_at }}
                    </div>
                    <div class="col-2 fs-13" v-if="assg?.submission">
                      {{ assg?.submission?.percentage }}
                    </div>
                    <div class="col-2 fs-13" v-else>Not Submitted</div>
                    <div v-if="assg?.type == 'assignment'" class="col-2 fs-13">
                      Individual
                    </div>
                    <div v-else class="col-2 fs-13">Group</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      items: [],
      module: "participants",
      permissions: null,
      isEdit: false,
      isDelete: false,
      isView: true,
      userId: "",
      courses: [],
      course: "",
      detail: [],
    };
  },
  mounted() {
    this.userId = this.$route.params.id;
    this.fetch(this.userId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("participants/getCourses", { id })
        .then((response) => {
          this.courses = response.data;
          this.course = this.courses[0]?.batch_id ?? "";
          this.selectedCourse();
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    selectedCourse() {
      this.loading = true;
      const data = {
        userid: this.userId,
        batchId: this.course,
      };
      this.$store
        .dispatch("participants/store", { data })
        .then((response) => {
          this.loading = false;
          this.detail = response.data;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    calculateGrade(totalPercentage) {
      if (totalPercentage < 50) {
        return "Failed";
      } else if (totalPercentage >= 50 && totalPercentage < 60) {
        return "D";
      } else if (totalPercentage >= 60 && totalPercentage < 70) {
        return "C";
      } else if (totalPercentage >= 70 && totalPercentage < 80) {
        return "B";
      } else if (totalPercentage >= 80 && totalPercentage <= 90) {
        return "A";
      } else if (totalPercentage > 90 && totalPercentage <= 100) {
        return "A+";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.select-form-control {
  border: 1px solid #0074ce !important;
  border-radius: 0px !important;
  height: 50px;
  width: auto !important;
  padding: 12px 25px !important;
  background-color: #fff !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button::after {
  width: 24px !important;
  height: 24px !important;
  background: url(../../assets/images/accordian-plus-icon.png) !important;
}

.accordion-button:not(.collapsed)::after {
  width: 24px !important;
  height: 24px !important;
  background: url(../../assets/images/accordian-minus-icon.png) !important;
}

.accordion-item {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.accordion-item {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.accordion-item:last-of-type
  > .accordion-header
  .accordion-button.collapsed[data-v-d1b650ca] {
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
}

.icon-sm img {
  width: 59px;
  height: 59px;
}
</style>
