<template>
    <div class="container mx-auto p-4">
      <h1 class="text-3xl font-bold mb-6">Reports</h1>
      <div class="row">
        <div 
          v-for="(report, index) in reports" 
          :key="index" 
          class="col-md-6 mb-4"
        >
          <div class="border rounded-lg shadow p-4">
            <div class="d-flex align-items-center gap-2 mb-2">
              <i :class="report.icon" class="h-6 w-6"></i>
              <h2 class="text-xl font-semibold">{{ report.title }}</h2>
            </div>
            <p class="text-gray-600 mb-4">{{ report.description }}</p>
            <router-link 
              :to="report.link" 
              class="block bg-blue-500 text-center py-2 rounded hover:bg-blue-600"
            >
              View Report
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ReportsDashboard",
    data() {
      return {
        reports: [
          {
            title: "Batch Report",
            description: "View and analyze batch processing results",
            icon: "fa fa-file-text",  // Font Awesome icon class
            link: "/reports/batch",
          },
          {
            title: "Participants Reports",
            description: "Detailed reports on participant data",
            icon: "fa fa-users",  // Font Awesome icon class
            link: "/reports/participants",
          },
          // Add more reports as needed
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here if needed */
  </style>
  