<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div
      class="modal fade"
      :id="'addMaterialModal' + index"
      tabindex="-1"
      aria-labelledby="addMaterialModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-5 mt-5">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Material
              </h5>
            </div>
            <form @submit.prevent="saveMaterial">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">
                    Material Type <span class="text-danger">*</span>
                  </label>
                  <div
                    class="btn-group-toggle d-flex flex-wrap material-type-container"
                    data-toggle="buttons"
                  >
                    <label class="btn w-20 mb-2 me-2">
                      <input
                        type="radio"
                        class="material-type"
                        v-model="courseMaterial.type"
                        value="Video"
                        autocomplete="off"
                      />
                      Video
                    </label>
                    <label class="btn w-20 mb-2 me-2">
                      <input
                        type="radio"
                        class="material-type"
                        v-model="courseMaterial.type"
                        value="Quiz"
                        autocomplete="off"
                      />
                      Quiz
                    </label>
                    <label class="btn w-20 mb-2">
                      <input
                        type="radio"
                        class="material-type"
                        v-model="courseMaterial.type"
                        value="Assignment"
                        autocomplete="off"
                      />
                      Assignment
                    </label>
                    <label class="btn w-20 mb-2">
                      <input
                        type="radio"
                        class="material-type"
                        v-model="courseMaterial.type"
                        value="Document"
                        autocomplete="off"
                      />
                      Document
                    </label>
                  </div>
                </div>
              </div>

              <div v-if="courseMaterial.type === 'Video'">
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color"
                      >Title <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="custom-form-control"
                      v-model="courseMaterial.title"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Description</label>
                    <textarea
                      class="custom-form-control"
                      v-model="courseMaterial.description"
                      style="height: 150px"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color"
                      >Upload Thumbnail
                      <span class="text-danger">*</span></label
                    >
                    <div class="position-relative">
                      <input
                        type="file"
                        ref="thumbnail"
                        class="position-relative"
                        accept="image/png, image/jpg, image/jpeg"
                        @change="selectThumbnail"
                        style="opacity: 0; z-index: 100"
                        required
                      />
                      <button
                        type="button"
                        class="btn btn-primary primary-bg-color position-absolute"
                        style="left: 0"
                      >
                        Browse...
                      </button>
                    </div>
                  </div>
                  <div class="image-group">
                    <div class="col-md-4 mt-3">
                      <img
                        v-if="courseMaterial.thumbnail"
                        :src="thumbnailPreview"
                        class="img-thumbnail"
                        style="width: 100%"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color"
                      >Upload Video</label
                    >
                    <div class="position-relative">
                      <input
                        type="file"
                        ref="video"
                        class="position-relative"
                        accept="video/*"
                        @change="selectVideo"
                        style="opacity: 0; z-index: 100"
                      />
                      <button
                        type="button"
                        class="btn btn-primary primary-bg-color position-absolute"
                        style="left: 0"
                      >
                        Browse...
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <div class="mb-2" v-if="fileUploadingPercentage > 0">
                      <progress :value="fileUploadingPercentage" max="100">
                        {{ fileUploadingPercentage }}%
                      </progress>
                      <p>{{ fileUploadingPercentage }}%</p>
                    </div>
                    <div v-if="videoPreview">
                      <video
                        :src="videoPreview"
                        class="mt-3 w-100"
                        id="video-preview"
                        ref="videoFile"
                        controls
                      ></video>
                      <!-- <video
                        v-if="courseMaterial.video"
                        id="video-preview"
                        :src="courseMaterial.video"
                        controls
                        class="w-100"
                      ></video> -->
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color"
                      >Youtube Link</label
                    >
                    <input
                      type="text"
                      class="custom-form-control"
                      v-model="courseMaterial.youtube"
                    />
                  </div>
                </div>
              </div>
              <div v-if="courseMaterial.type === 'Quiz'">
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Select Quiz</label>
                    <select
                      class="custom-form-control"
                      v-model="courseMaterial.material_id"
                      required
                    >
                      <option value="" disabled>Select Quiz</option>
                      <option
                        v-for="quiz in quizes"
                        :key="quiz.id"
                        :value="quiz.id"
                      >
                        {{ quiz.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="courseMaterial.type === 'Assignment'">
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Select Assignment</label>
                    <select
                      class="custom-form-control"
                      v-model="courseMaterial.material_id"
                      required
                    >
                      <option value="" disabled>Select Assignment</option>
                      <option
                        v-for="quiz in assesments"
                        :key="quiz.id"
                        :value="quiz.id"
                      >
                        {{ quiz.description }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="courseMaterial.type === 'Document'">
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color"
                      >Title <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="custom-form-control"
                      v-model="courseMaterial.title"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Description</label>
                    <textarea
                      class="custom-form-control"
                      v-model="courseMaterial.description"
                      style="height: 150px"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <div class="form-group">
                      <div class="col-md-12 mt-3">
                        <label class="primary-text-color"
                          >Upload Document
                          <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <input
                            type="file"
                            ref="thumbnail"
                            class="position-relative"
                            accept="application/pdf, application/msword"
                            @change="selectDocument"
                            style="opacity: 0; z-index: 100"
                            required
                          />
                          <button
                            type="button"
                            class="btn btn-primary primary-bg-color position-absolute"
                            style="left: 0"
                          >
                            Browse...
                          </button>
                        </div>
                      </div>
                      <div class="image-group">
                        <div class="col-md-4 mt-3">
                          <a v-if="document" :href="document" download
                            >Perview Document</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div v-if="uploadProgress > 0">
                  <progress :value="uploadProgress" max="100">
                    {{ uploadProgress }}%
                  </progress>
                  <p>{{ uploadProgress }}%</p>
                </div>
                <div class="col-md-9">
                  <button
                    type="submit"
                    :disabled="fileUploadingPercentage > 0"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    <span v-if="fileUploadingPercentage > 0"
                      >Please wait, uploading file...</span
                    >
                    <span v-else>Save</span>
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    data-bs-dismiss="modal"
                    @click="reloadData"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import axios from "@/helpers/axios";

export default {
  props: {
    index: null,
    module: null,
    selectedItem: null,
  },
  data() {
    return {
      loading: false,
      courseMaterial: {
        title: null,
        description: null,
        course_level_id: null,
        thumbnail: null,
        video: null,
        material_id: "",
        type: "Video",
        duration: null,
        document: "",
        youtube: null,
      },
      thumbnailPreview: null,
      materialType: "video",
      videoPreview: null,
      materialId: null,
      material: null,
      quizes: [],
      assesments: [],
      uploadProgress: 0,
      document: "",
      role: null,
      fileUploadingPercentage: 0,
      videoFile: "",
    };
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.fetch();
    this.fetchAssesment();
  },
  methods: {
    selectThumbnail() {
      let picture = this.$refs.thumbnail.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.thumbnailPreview = e.target.result;
      };
      reader.onloadend = () => {
        this.courseMaterial.thumbnail = reader.result;
      };
      reader.readAsDataURL(picture);
    },
    selectDocument() {
      let picture = this.$refs.thumbnail.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.document = e.target.result;
      };
      reader.onloadend = () => {
        this.courseMaterial.document = reader.result;
      };
      reader.readAsDataURL(picture);
    },

    async uploadVideo(event) {
      const file = event.target.files[0];
      this.videoFile = file;
      this.videoPreview = URL.createObjectURL(file);

      const formData = new FormData();
      formData.append("video", this.videoFile);

      axios
        .post("/upload-video", formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            this.fileUploadingPercentage = progress === 100 ? 99 : progress; // Show 99% until server responds
          },
        })
        .then((response) => {
          console.log("File uploaded successfully:", response.data);
          this.courseMaterial.video = response.data.data;
          this.fileUploadingPercentage = 0;
        })
        .catch((error) => {
          if (error.status) {
            // Handle HTTP errors (with status)
            console.error(
              "Upload failed with status:",
              error.status,
              error.data || error.message
            );
          } else {
            // Handle network or request setup errors
            console.error("Upload failed:", error.message);
          }
        });
    },

    selectVideo(event) {
      const file = event.target.files[0];
      this.videoPreview = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onloadend = async () => {
        this.$nextTick(() => {
          let video = document.getElementById("video-preview");
          video.src = this.videoPreview;
          video.onloadedmetadata = () => {
            this.courseMaterial.duration = parseFloat(
              (video.duration / 60).toFixed(2)
            );
          };
        });
      };
      reader.readAsDataURL(file);
      this.fileUploadingPercentage = 0.1;
      const chunkSize = 5 * 1024 * 1024;
      this.uploadFileInChunks(file, chunkSize);
    },
    async uploadFileInChunks(file, chunkSize) {
      const totalChunks = Math.ceil(file.size / chunkSize);
      const timestamp = Math.floor(Date.now() / 1000);
      const fileExtension = file.name.split(".").pop();
      const folder = "course_material_videos";
      this.fileUploadingPercentage = "0.01";
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append("chunk", chunk);
        formData.append("index", i + 1);
        formData.append("totalChunks", totalChunks);
        formData.append("timestamp", timestamp);
        formData.append("fileExtension", fileExtension);
        formData.append("folder", folder);
        const response = await axios.post("/upload-video", formData);
        if (response.data.success) {
          this.fileUploadingPercentage = (
            ((i + 1) / totalChunks) *
            100
          ).toFixed(2);
          this.courseMaterial.video = {
            path: response.data.file.path,
            name: file.type,
          };
          if (response.data.videoStatus == true) {
            console.log("helllo");
            this.fileUploadingPercentage = 0;
          }
        } else {
          this.$toast.show(response.data.message, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 5000,
          });
          return;
        }
      }
    },
    saveMaterial() {
      this.loading = true;
      this.courseMaterial.course_level_id = this.selectedItem.id;
      const data = toRaw(this.courseMaterial);
      this.$store
        .dispatch("courseMaterial/store", {
          data,
          onUploadProgress: this.onUploadProgress,
        })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            if (this.courseMaterial.type == "Video") {
              this.thumbnailPreview = null;
              this.videoPreview = null;
              this.$refs.thumbnail.value = "";
              this.$refs.video.value = "";
            }
            Object.keys(this.courseMaterial).forEach(
              (key) => (this.courseMaterial[key] = null)
            );
            this.courseMaterial.material_id = "";
            this.courseMaterial.type = "Video";
            this.document = "";
            this.uploadProgress = 0;
            this.fileUploadingPercentage = 0;
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    onUploadProgress(progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    fetch() {
      this.loading = true;
      const search = "";
      const pagination = false;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("quiz/getAll", { search, pagination, facilitator_id })
        .then((response) => {
          this.quizes = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchAssesment() {
      this.loading = true;
      const search = "";
      const pagination = false;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("manualAssessment/getAll", {
          search,
          pagination,
          facilitator_id,
        })
        .then((response) => {
          this.assesments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>
