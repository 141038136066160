<template>
  <div id="piechart">
    <apexchart
      ref="chart"
      type="pie"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
  
  <script>
export default {
  props: {
    data: Array,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 450,
          type: "pie",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                height: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    series() {
      return this.data.map((district) => district.users);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.updateLabels(this.data);
    });
  },
  watch: {
    data: {
      handler(newData) {
        this.updateLabels(newData);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateLabels(districts) {
      this.chartOptions.labels = districts.map(
        (district) =>
          `${district.district_name}: ${district.users} participants`
      );
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions({
          labels: this.chartOptions.labels,
        });
      } else {
        console.error("Chart not available.");
      }
    },
  },
};
</script>