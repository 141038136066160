<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-10 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Batch
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Course <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    v-model="batch.course_id"
                    @change="courseSelected"
                    required
                  >
                    <option value="">Select Course</option>
                    <option
                      v-for="course in courses"
                      :value="course.encrypted_id"
                    >
                      {{ course.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Title <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="custom-form-control"
                    v-model="batch.title"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color"
                    >Start Date <span class="text-danger">*</span></label
                  >
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.start_date"
                    required
                    :min="today"
                    @change="getEndDate"
                  />
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color"
                    >End Date <span class="text-danger">*</span></label
                  >
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.end_date"
                    required
                    :min="today"
                    readonly
                    disabled
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Vacation Start Date</label>
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.vacation_start_date"
                    :min="today"
                  />
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Vacation End Date</label>
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.vacation_end_date"
                    :min="today"
                  />
                </div>
              </div>
              <div class="form-group" v-if="role != null">
                <div class="col-md-12 mt-3">
                  <div class="row justify-content-between">
                    <label class="primary-text-color w-auto"
                      >Facilitator <span class="text-danger">*</span></label
                    >
                    <label
                      class="text-underline primary-text-color w-auto cursor-pointer"
                      @click="teacherDisabled = false"
                      >Edit</label
                    >
                  </div>
                  <select
                    class="custom-form-control"
                    v-model="batch.teacher_id"
                    required
                    :disabled="teacherDisabled"
                  >
                    <option value="">Select Facilitator</option>
                    <option v-for="teacher in teachers" :value="teacher.id">
                      {{ teacher.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="p-4 mt-3"
                style="
                  border-radius: 16px;
                  background-color: rgba(0, 116, 206, 0.2);
                "
              >
                <h5>Search Participants</h5>
                <div class="form-group row">
                  <div class="col-md-6 mt-3">
                    <label class="primary-text-color">Department</label>
                    <select
                      class="custom-form-control"
                      v-model="department_id"
                      @change="fetchStudents"
                    >
                      <option value="">Select Department</option>
                      <option
                        v-for="department in departments"
                        :value="department.id"
                      >
                        {{ department.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label class="primary-text-color">Qualification</label>
                    <select
                      class="custom-form-control"
                      v-model="qualification"
                      @change="fetchStudents"
                    >
                      <option value="">Select Qualification</option>
                      <option
                        v-for="qualification in qualifications"
                        :value="qualification.name"
                      >
                        {{ qualification.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label class="primary-text-color">Designation</label>
                  <select
                    class="custom-form-control"
                    v-model="designation"
                    @change="fetchStudents"
                  >
                    <option value="">Select Designation</option>
                    <option
                      v-for="designation in designations"
                      :value="designation.name"
                    >
                      {{ designation.name }}
                    </option>
                  </select>
                </div>
                <div class="mt-3" v-if="students.data.length > 0">
                  <p>
                    Participants Found: {{ students.data.length }} &nbsp;
                    &nbsp;<a
                      href="javascript:void(0);"
                      class="primary-text-color text-underline"
                      data-bs-toggle="modal"
                      data-bs-target="#participantModal"
                      >View List</a
                    >
                  </p>
                </div>
                <div class="mt-3" v-if="allStudents.data.length > 0">
                  <p>
                    Total Participants: {{ allStudents.data.length }} &nbsp;
                    &nbsp;<a
                      href="javascript:void(0);"
                      class="primary-text-color text-underline"
                      data-bs-toggle="modal"
                      data-bs-target="#totalParticipantModal"
                      >View List</a
                    >
                  </p>
                </div>
              </div>
              <div v-if="course && course.levels" class="row mt-3">
                <h4>Schedule your course material.</h4>
                <div
                  v-for="(level, tableIndex) in course.levels"
                  :key="tableIndex"
                >
                  <h5 class="mt-3">{{ level.name }}</h5>
                  <div class="form-group">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="level.mode"
                        :id="'auto' + level.id"
                        value="auto"
                        :name="'modeName-' + level.name"
                        required
                      />
                      <label class="form-check-label" :for="'auto' + level.id"
                        >Auto</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="level.mode"
                        :id="'manual' + level.id"
                        value="manual"
                        :name="'modeName-' + level.name"
                        required
                      />
                      <label class="form-check-label" :for="'manual' + level.id"
                        >Manual</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="level.mode"
                        :id="'open' + level.id"
                        value="open"
                        :name="'modeName-' + level.name"
                        required
                      />
                      <label class="form-check-label" :for="'open' + level.id"
                        >Open</label
                      >
                    </div>
                  </div>

                  <table
                    class="table table-striped table-hover table-responsive"
                  >
                    <thead>
                      <tr>
                        <th style="width: 150px">Material Number</th>
                        <th style="width: 150px">Material Type</th>
                        <th>Material Title</th>
                        <th v-if="level.mode === 'manual'">Select Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, rowIndex) in level.material"
                        :key="row.id"
                      >
                        <td>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            :id="`sortingValue-${tableIndex}-${rowIndex}`"
                            v-model="row.sorting"
                            @keydown="isNumber($event)"
                            style="width: 50px"
                          />
                        </td>
                        <td>{{ row.type }}</td>
                        <td v-if="row.type === 'Video'">
                          {{ row.material.title }}
                        </td>
                        <td v-if="row.type === 'Quiz'">
                          {{ row.material.name }}
                        </td>
                        <td v-if="row.type === 'Assignment'">
                          {{ row?.material?.description }}
                        </td>
                        <td v-if="row.type === 'Document'">
                          {{ row?.material?.title }}
                        </td>
                        <td v-if="level.mode === 'manual'">
                          <input
                            type="date"
                            v-model="row.date"
                            class="form-control form-control-sm"
                            required
                            :min="today"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/batch"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Participant List Modal -->
    <div
      class="modal fade"
      id="participantModal"
      tabindex="-1"
      aria-labelledby="participantModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-5 mt-5">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Participant List
              </h5>
            </div>
            <data-table
              :columns="columns"
              :items="students"
              :isEdit="isEdit"
              :isDelete="isDelete"
              :isChecked="isChecked"
              @checkboxUpdated="handleCheckboxUpdated"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
    <!--Total Participant List Modal -->
    <div
      class="modal fade"
      id="totalParticipantModal"
      tabindex="-1"
      aria-labelledby="totalParticipantModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-5 mt-5">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Participant List
              </h5>
            </div>
            <data-table
              :columns="columns"
              :items="allStudents"
              :isEdit="isEdit"
              :isDelete="isDelete"
              :isTrashed="isTrashed"
              @checkboxUpdated="handleCheckboxUpdated"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import dataTable from "@/components/dataTable.vue";
import draggable from "vuedraggable";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  components: {
    dataTable,
    draggable,
  },
  data() {
    return {
      loading: false,
      batch: {
        title: null,
        course_id: "",
        teacher_id: "",
        start_date: null,
        end_date: null,
        vacation_start_date: null,
        vacation_end_date: null,
        student_ids: [],
      },
      id: "",
      formSubmitted: false,
      courses: [],
      course: [],
      teachers: [],
      departments: [],
      department_id: "",
      students: {
        data: [],
      },
      allStudents: {
        data: [],
      },
      qualification: "",
      designation: "",
      columns: [
        {
          text: "Participant Name",
          value: "name",
        },
        {
          text: "Designation",
          value: "designation",
        },
        {
          text: "Department",
          value: "department",
          secondValue: "name",
        },
        {
          text: "Qualification",
          value: "qualification",
        },
      ],
      isEdit: false,
      isDelete: false,
      isChecked: true,
      isTrashed: true,
      role: localStorage.getItem("role"),
      qualifications: [],
      designations: [],
      today: null,
      teacherDisabled: true,
    };
  },
  mounted() {
    this.fetchCourses();
    this.fetchTeachers();
    this.fetchDepartments();
    this.fetchQualifications();
    this.fetchDesignations();
    this.today = new Date().toISOString().split("T")[0];
  },
  methods: {
    fetchStudents() {
      this.loading = true;
      const department = this.department_id;
      const qualification = this.qualification;
      const designation = this.designation;
      this.$store
        .dispatch("participants/filterStudents", {
          department,
          qualification,
          designation,
        })
        .then((response) => {
          this.students.data = response.data.filter((student) => {
            return !this.allStudents.data.some(
              (existingStudent) => existingStudent.id === student.id
            );
          });
          this.allStudents.data.push(...this.students.data);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchCourses() {
      this.loading = true;
      const search = "";
      const pagination = false;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("courses/getAll", { search, pagination, facilitator_id })
        .then((response) => {
          this.courses = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchTeachers() {
      this.loading = true;
      const type = "Teacher";
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("facilitators/getAll", { type, search, pagination })
        .then((response) => {
          this.teachers = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchDepartments() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("department/getAll", { search, pagination })
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchQualifications() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("qualification/getAll", { search, pagination })
        .then((response) => {
          this.qualifications = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchDesignations() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("designation/getAll", { search, pagination })
        .then((response) => {
          this.designations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    handleCheckboxUpdated(item) {
      const index = this.allStudents.data.findIndex(
        (student) => student.id === item.id
      );
      if (index !== -1) {
        this.allStudents.data.splice(index, 1);
      } else {
        this.allStudents.data.push(item);
      }
    },
    courseSelected() {
      this.loading = true;
      this.formSubmitted = true;
      const id = this.batch.course_id;
      this.$store
        .dispatch("courses/getSingle", { id })
        .then((response) => {
          this.course = response.data;
          this.batch.teacher_id = this.course.facilitator.id;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    submit() {
      let notHasMaterial = this.course.levels.some(
        (level) => Array.isArray(level.material) && level.material.length === 0
      );
      if (notHasMaterial) {
        this.$toast.show("One or more level has empty material.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        this.allStudents.data.forEach((item) => {
          this.batch.student_ids.push(item.id);
        });
        if (this.batch.student_ids.length <= 0) {
          this.$toast.show("Please add some participants", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 5000,
          });
        } else {
          this.loading = true;
          const data = toRaw(this.batch);
          data.courseDetails = this.course;
          data.teacher_id =
            this.role == "teacher"
              ? localStorage.getItem("uid")
              : this.batch.teacher_id;
          this.$store
            .dispatch("batch/store", { data })
            .then((response) => {
              this.loading = false;
              if (response.success == true) {
                this.$toast.show("Successfuly added.", {
                  theme: "outline",
                  position: "top",
                  type: "success",
                  duration: 5000,
                });
                Object.keys(this.batch).forEach(
                  (key) => (this.batch[key] = null)
                );
                this.batch.course_id = "";
                this.batch.teacher_id = "";
                this.department_id = "";
                this.qualification = "";
                this.designation = "";
                this.students.data = [];
                this.allStudents.data = [];
                this.formSubmitted = true;
                this.course = [];
              } else {
                this.errors = response.error;
                console.log(response.error);
              }
            })
            .catch((e) => {
              console.log(e.error);
              this.loading = false;
            });
        }
      }
    },
    clearAll() {
      Object.keys(this.batch).forEach((key) => (this.batch[key] = null));
      this.batch.course_id = "";
      this.batch.teacher_id = "";
      this.department_id = "";
      this.qualification = "";
      this.designation = "";
      this.students.data = [];
      this.allStudents.data = [];
    },
    getEndDate() {
      let date = new Date(this.batch.start_date);
      date.setMonth(date.getMonth() + this.course.duration);
      let day = String(date.getDate()).padStart(2, "0");
      let month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      let year = date.getFullYear();
      let formatedDate = `${year}-${month}-${day}`;
      this.batch.end_date = formatedDate;
    },
  },
};
</script>
<style scoped>
.modal-content {
  min-height: 800px;
  max-height: 800px;
}
</style>
