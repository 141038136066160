<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Export Participants Report
              </h5>
            </div>
          </div>

          <p class="card-description">
            Select criteria for your report export.
          </p>
          <!-- District -->
          <div class="space-y-2">
            <label for="district">District</label>
            <select
              @change="fetchTahseel"
              v-model="filterData.district_id"
              id="district"
            >
              <option value="" disabled>Select a district</option>
              <option
                v-for="(district, index) in districts"
                :key="index"
                :value="district.id"
              >
                {{ district.name }}
              </option>
            </select>
          </div>

          <!-- Tehsil -->
          <div class="space-y-2 mt-2">
            <label for="tehsil">Tehsil</label>
            <select v-model="filterData.tahseel_id" id="tehsil">
              <option value="" disabled>Select a tehsil</option>
              <option
                v-for="(tahseel, index) in tahseels"
                :key="index"
                :value="tahseel.id"
              >
                {{ tahseel.name }}
              </option>
            </select>
          </div>

          <!-- Gender -->
          <div class="space-y-2 mt-2">
            <label>Gender</label>
            <div class="flex space-x-4">
              <label>
                <input type="radio" v-model="filterData.gender"  value="Male" /> Male
              </label>
              <label>
                <input type="radio" v-model="filterData.gender" value="Female" /> Female
              </label>
            </div>
          </div>

          <div class="card-footer">
            <button @click="handleExport" class="w-full">
              <span>Export Report</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import dataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      filterData: {
        district_id: "",
        tahseel_id: "",
        gender: "",
      },
      items: [],
      districts: [],
      tahseels: [],
      module: "reports",
    };
  },
  mounted() {
    this.fetchAllDistrcts();
  },
  methods: {
    fetchAllDistrcts() {
      this.loading = true;
      this.$store
        .dispatch("district/getAll", { search: "", pagination: false })
        .then((response) => {
          this.districts = response.data;
          console.log("districts: ", this.districts);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    fetchTahseel() {
      this.loading = true;
      const id = this.filterData.district_id;
      this.$store
        .dispatch("tahseel/getByDistrict", { id })
        .then((response) => {
          this.tahseels = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    handleExport() {
      this.loading = true;
      const data = this.filterData;
      console.log(data);
      this.$store
        .dispatch("participants/exportPartcipants", {data} )
        .then((response) => {
          window.location.href = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
  

<style scoped>
.card {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
}

.card-header {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-description {
  font-size: 1rem;
  color: #555;
}

.card-content {
  margin-top: 16px;
}

.card-footer {
  margin-top: 10px;
}

button {
  padding: 8px 16px;
  background-color: #0074ce;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
}

select,
input[type="date"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.flex {
  display: flex;
}

.flex.space-x-4 > * {
  margin-right: 16px;
}

.calendar {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
}
</style>