<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Participant
              </h5>
            </div>
            <form @submit.prevent="submit" autocomplete="off">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Email <span class="text-danger">*</span></label>
                  <span class="d-block text-danger">{{
                    errors?.username
                  }}</span>
                  <input type="email" v-model="user.username" class="custom-form-control" required
                    pattern="[^\s@]+@[^\s@]+\.[^\spatt@]+" autocomplete="new-email" />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">
                    Password <span class="text-danger">*</span>
                  </label>

                  <div class="d-flex align-items-center">
                    <input :type="passwordFieldType" v-model="user.password" class="custom-form-control mr-2 me-1"
                      autocomplete="new-password" required />
                    <span class="input-group-text me-1" @click="togglePasswordVisibility">
                      <i :class="passwordFieldType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                    </span>
                    <span class="input-group-text ml-2 me-1" @click="generatePassword">
                      <i class="fa fa-key"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">
                    Re-Type Password <span class="text-danger">*</span>
                  </label>
                  <span class="text-danger d-block">
                    {{ errors?.confirmPassword }}
                  </span>
                  <div class="input-group">
                    <input :type="passwordFieldType" v-model="user.confirmPassword" class="custom-form-control"
                      autocomplete="new-password" required />

                  </div>
                </div>
              </div>
              <div class="form-group row mt-3">
                <div class="col-md-3">
                  <img :src="previewProfilePic" style="
                      width: 120px;
                      height: 120px;
                      border-radius: 50%;
                      border: 1px solid #ddd;
                    " />
                </div>
                <div class="col-md-8 mt-3">
                  <label>Upload Profile Picture</label>
                  <div class="position-relative">
                    <input type="file" ref="profilePic" class="position-relative"
                      accept="image/png, image/jpg, image/jpeg" @change="profilePicSelected"
                      style="opacity: 0; z-index: 100" />
                    <button type="button" class="btn btn-primary primary-bg-color position-absolute" style="left: 0">
                      Browse...
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group row mt-3">
                <div class="col-md-12">
                  <label class="primary-text-color">Name <span class="text-danger">*</span></label>
                  <input type="text" class="custom-form-control" v-model="profile.name" required
                    @keydown="validateText($event)" />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Department <span class="text-danger">*</span></label>
                  <select class="custom-form-control" v-model="profile.department_id" required>
                    <option value="">Select Department</option>
                    <option v-for="department in departments" :value="department.id">
                      {{ department.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Designation</label>
                  <select v-model="profile.designation" class="custom-form-control">
                    <option value="" selected>Select Designation</option>
                    <option v-for="des in designations" :value="des.name">
                      {{ des.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Select Qualification
                    <span class="text-danger">*</span></label>
                  <select v-model="profile.qualification" class="custom-form-control" required>
                    <option value="">Select Qualification</option>
                    <option v-for="des in qualifications" :value="des.name">
                      {{ des.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Select District <span class="text-danger">*</span></label>
                  <select class="custom-form-control" required v-model="profile.district_id" @change="fetchTahseel">
                    <option v-for="district in districts" :value="district.id">
                      {{ district.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Select Tehsil</label>
                  <select class="custom-form-control" v-model="profile.tahseel_id">
                    <option v-for="tahseel in tahseels" :value="tahseel.id">
                      {{ tahseel.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Phone <span class="text-danger">*</span></label>
                  <span class="d-block text-danger">{{ errors?.phone }}</span>
                  <input type="text" v-model="profile.phone" maxlength="11" minlength="11" class="custom-form-control"
                    @keypress="isNumber($event)" required />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Gender</label>
                  <div class="col-md-12">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="male" value="1" v-model="profile.gender" />
                      <label class="form-check-label primary-text-color" for="male">Male</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="female" value="2" v-model="profile.gender" />
                      <label class="form-check-label primary-text-color" for="female">Female</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Date of Birth</label>
                  <input type="date" v-model="profile.dob" class="custom-form-control" max="2005-12-31" />
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">CNIC </label>
                  <span class="d-block text-danger">{{ errors?.cnic }}</span>
                  <input type="text" v-model="profile.cnic" maxlength="13" minlength="13" class="custom-form-control"
                     @keypress="isNumber($event)" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <button type="submit" class="custom-btn primary-bg-color text-white btn-block w-100 mt-3">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button type="button" class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll">
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link to="/participants"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3">Cancel</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import validateText from "@/helpers/helpers";

export default {
  mixins: [isNumber, validateText],
  data() {
    return {
      loading: false,
      user: {
        username: null,
        password: null,
        confirmPassword: null,
        type: "Student",
        profile: null,
      },
      profile: {
        name: null,
        department_id: "",
        designation: "",
        phone: null,
        gender: null,
        dob: null,
        email: null,
        cnic: null,
        qualification: null,
        type: "Student",
        tahseel_id: null,
        district_id: null,
      },
      designations: [],
      qualifications: [],
      errors: [],
      profilePicture: null,
      previewProfilePic: null,
      uploadProfilePic: null,
      departments: [],
      districts: [],
      districtId: null,
      tahseels: [],
      passwordFieldType: 'password',
    };
  },
  mounted() {
    this.fetchDepartments();
    this.fetchDesigantion();
    this.fetchQualifications();
    this.fetchDistrict();
  },
  methods: {
    fetchDistrict() {
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("district/getAll", { search, pagination })
        .then((response) => {
          this.districts = response.data;
        })
        .catch((e) => {
          console.log("district:", e);
        });
    },
    fetchTahseel() {
      this.loading = true;
      const id = this.profile.district_id;
      this.$store
        .dispatch("tahseel/getByDistrict", { id })
        .then((response) => {
          this.tahseels = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    fetchDepartments() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("department/getAll", { search, pagination })
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    profilePicSelected() {
      this.profilePicture = this.$refs.profilePic.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.previewProfilePic = e.target.result;
      };
      reader.onloadend = () => {
        this.uploadProfilePic = reader.result;
      };
      reader.readAsDataURL(this.profilePicture);
    },
    submit() {
      this.loading = true;
      if (this.user.password != this.user.confirmPassword) {
        this.errors.confirmPassword = "Password didn't match";
        this.loading = false;
      }
      this.user.profile = this.profile;
      if (this.uploadProfilePic != null) {
        this.user.profile.file = this.uploadProfilePic;
      }
      this.user.profile.email = this.user.username;
      this.errors = null;
      const data = toRaw(this.user);
      this.$store
        .dispatch("users/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.user).forEach((key) => (this.user[key] = null));
            Object.keys(this.profile).forEach(
              (key) => (this.profile[key] = null)
            );
            this.profile.type = "Student";
            this.user.type = "Student";
            this.profilePicture = null;
            this.previewProfilePic = null;
            this.uploadProfilePic = null;
            this.$refs.profilePic.value = null;
            this.profile.designation = "";
            this.errors = null;
          } else {
            this.$toast.show("Please review your form.", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
            this.errors = response.error;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    clearAll() {
      Object.keys(this.user).forEach((key) => (this.user[key] = null));
      Object.keys(this.profile).forEach((key) => (this.profile[key] = null));
      this.profile.type = "Student";
      this.user.type = "Student";
      this.profilePicture = null;
      this.previewProfilePic = null;
      this.uploadProfilePic = null;
      this.$refs.profilePic.value = null;
      this.profile.designation = "";
    },
    fetchDesigantion() {
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("designation/getAll", { search, pagination })
        .then((response) => {
          this.designations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    fetchQualifications() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("qualification/getAll", { search, pagination })
        .then((response) => {
          this.qualifications = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    generatePassword() {
      const length = 8;
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let password = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }
      this.user.password = password;
      this.user.confirmPassword = password;
    },
  },
};
</script>
